<template>
  <div class="table_header">
    <el-row type="flex" align="middle" style="height: 100%">
      <el-col :md="9" style="text-align: left">
        <h3 style="padding-left: 40px">
          <i :class="icon" />
          <span>{{ title }}</span>
        </h3>
      </el-col>
      <el-col :md="6">
        <el-input
          placeholder="请输入姓名/手机/电话"
          v-model="search_input"
          class="input-with-select"
          size="medium"
        >
          <el-button slot="append" class="search_btn" size="medium">
            搜索
          </el-button>
        </el-input>
      </el-col>
      <el-col :md="9" style="text-align: right; padding-right: 20px">
        <el-button
          size="small"
          class="new_contacts_btn"
          @click="showDialog = true"
        >
          + 新建
        </el-button>
        <el-button size="small">查重</el-button>
        <el-button size="small">更多</el-button>
      </el-col>
    </el-row>
    <el-dialog title="新建" :visible.sync="showDialog" width="30%">
      <slot name="dialog" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="insertCustomer"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 表格头部需要的参数：基本上是几个插槽，包括表格标题，搜索框，操作按钮以及请求地址
export default {
  name: 'TableHeader',
  data() {
    return {
      search_input: '',
      showDialog: false,
    };
  },
  methods: {
    insertCustomer() {
      this.showDialog = false;
      this.$emit('insertCustomer');
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'el-icon-user-solid',
      required: false,
    },
  },
};
</script>

<style>
.table_header {
  background-color: #f5f6f9;
  height: 60px;
}
.el-input-group__append {
  border: none;
}
.search_btn {
  background-color: #2362fb !important;
  color: white !important;
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: -5px !important;
}
.new_contacts_btn {
  background-color: #ff6a00;
  border-color: #ff6a00;
  color: white;
}
.new_contacts_btn:hover {
  background-color: #ff6a00;
  border-color: #ff6a00;
  color: white;
  opacity: 0.8;
}
</style>
