<template>
  <div>
    <el-table
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '5px' }"
      :header-cell-style="{ padding: '5px', background: '#eef1f6' }"
      :data="tableData"
      border
      style="width: 100%"
      height="500"
      stripe
      size="small"
      @selection-change="addDeleteData"
      @cell-click="cellClick"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column
        v-for="item in columnConfig"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :width="item.label.length < 3 ? '80' : '140'"
        style="height: 40px"
        show-overflow-tooltip
        :class-name="isJumpColumn(item.label) ? 'jump_column' : ''"
        label-class-name="label_title"
        sortable
      />
      <el-table-column fixed="right" label="编辑" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="getEditData(scope.row)">
            更改数据
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="更新" :visible.sync="showDialog" width="30%">
      <slot name="dialog" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="updateData"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Table',
  data() {
    return {
      showDelBtn: false,
      showDialog: false,
    };
  },
  methods: {
    isJumpColumn(title) {
      if (!this.jumpColumn) return;
      return this.jumpColumn.includes(title);
    },
    addDeleteData(val) {
      this.$emit('getDelData', val);
    },
    getEditData(data) {
      this.showDialog = true;
      this.$emit('editData', data);
    },
    updateData() {
      this.showDialog = false;
      this.$emit('updateData');
    },
    cellClick(row, column) {
      if (this.isJumpColumn(column.label)) this.$emit('cellClick', row);
    },
  },
  props: {
    columnConfig: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    jumpColumn: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style>
.label_title {
  color: black;
}
.jump_column {
  color: #2362fb !important;
  cursor: pointer;
  font-weight: 700;
  height: 20px;
}
</style>
