<template>
  <div class="filter_box">
    <el-row type="flex" align="middle" style="height: 100%" :gutter="10">
      <el-col :md="1" style="text-align: right">
        <span style="font-size: 14px">场景:</span>
      </el-col>
      <el-col :md="4">
        <el-select v-model="selection" placeholder="全部联系人" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.value"
            class="option_text"
          >
            <span>{{ item.label }}</span>
          </el-option>
          <el-option value="新建场景">
            <div>
              <i class="el-icon-add-location" style="color: #2362fb" />
              <span style="padding-left: 5px">新建场景</span>
            </div>
          </el-option>
          <el-option value="管理">
            <div>
              <i class="el-icon-s-management" style="color: #2362fb" />
              <span style="padding-left: 5px">管理</span>
            </div>
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="2">
        <el-button size="small" class="filter_btn">
          <i class="el-icon-search" />
          <span>高级筛选</span>
        </el-button>
      </el-col>
      <el-col :md="1" v-show="showDelBtn">
        <el-button type="danger" size="small" @click="$emit('deleteTableData')">
          删除
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 筛选组件需要的参数：请求地址，筛选参数，弹窗内容等
export default {
  name: 'FilterHeader',
  data() {
    return {
      options: [
        {
          value: '我负责的',
          label: '我负责的',
        },
        {
          value: '下属负责的',
          label: '下属负责的',
        },
        {
          value: '全部联系人',
          label: '全部联系人',
        },
        {
          value: '我的关注',
          label: '我的关注',
        },
      ],
      selection: '',
    };
  },
  props: {
    showDelBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.filter_box {
  background-color: white;
  height: 50px;
}
.option_text {
  font-size: 13px;
}
.option_text:hover {
  color: #2362fb;
}
.filter_btn {
  color: white;
  background-color: #2362fb;
}
.filter_btn:hover {
  opacity: 0.8;
}
</style>
