<template>
  <div style="padding-top: 5px">
    <PositionTable />
    <PositionPagination />
  </div>
</template>

<script>
import PositionTable from './PositionTable';
import PositionPagination from './PositionPagination';
export default {
  name: 'PositionContainer',
  components: { PositionPagination, PositionTable },
};
</script>
