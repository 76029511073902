<template>
  <div class="pagination_box">
    <el-pagination
      class="pagination_content"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="10"
      layout="prev, pager, next, sizes, total, jumper"
      :total="100"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
// 分页将来可能需要的参数：请求地址
export default {
  name: 'Pagination',
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.pagination_box {
  width: 100%;
  background-color: white;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination_content {
  padding-right: 100px !important;
}
</style>
